import gql from "graphql-tag";

const GET_REVIEWS_FOR_MODEL = gql`
  query GetReviewsForModel($modelId: ObjectId) {
    reviews(query: { modelId: $modelId }, sortBy: DATE_DESC) {
      _id
      modelId
      modelReviewed
      date
      excerpt
      author
      rating
      reviewSourceId
      reviewSourceLogo
      url
      featured
      hideURL
      inUK
    }
  }
`;

const ADD_REVIEW = gql`
  mutation AddReview(
    $reviewSourceId: ObjectId
    $reviewSourceLogo: String
    $modelId: ObjectId
    $url: String
    $hideURL: Boolean
    $featured: Boolean
    $modelReviewed: String
    $rating: Float
    $date: DateTime
    $excerpt: String
    $author: String
    $inUK: Boolean
  ) {
    insertOneReview(
      data: {
        reviewSourceId: $reviewSourceId
        reviewSourceLogo: $reviewSourceLogo
        modelId: $modelId
        url: $url
        hideURL: $hideURL
        featured: $featured
        modelReviewed: $modelReviewed
        rating: $rating
        date: $date
        excerpt: $excerpt
        author: $author
        inUK: $inUK
      }
    ) {
      _id
      url
    }
  }
`;

const UPDATE_REVIEW = gql`
  mutation UpdateReview(
    $reviewId: ObjectId
    $reviewSourceId: ObjectId
    $reviewSourceLogo: String
    $modelId: ObjectId
    $url: String
    $hideURL: Boolean
    $featured: Boolean
    $modelReviewed: String
    $rating: Float
    $date: DateTime
    $excerpt: String
    $author: String
    $inUK: Boolean
  ) {
    updatedReview: updateOneReview(
      query: { _id: $reviewId }
      set: {
        reviewSourceId: $reviewSourceId
        reviewSourceLogo: $reviewSourceLogo
        modelId: $modelId
        url: $url
        hideURL: $hideURL
        featured: $featured
        modelReviewed: $modelReviewed
        rating: $rating
        date: $date
        excerpt: $excerpt
        author: $author
        inUK: $inUK
      }
    ) {
      _id
      url
    }
  }
`;

const DELETE_REVIEW = gql`
  mutation DeleteReview($reviewId: ObjectId!) {
    deletedReview: deleteOneReview(query: { _id: $reviewId }) {
      _id
      url
    }
  }
`;

export { GET_REVIEWS_FOR_MODEL, ADD_REVIEW, UPDATE_REVIEW, DELETE_REVIEW };
