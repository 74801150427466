import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button } from 'reactstrap';
import EditReviewForm from './editReviewForm';
import AddReviewForm from './addReviewForm';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_REVIEWS_FOR_MODEL,
  ADD_REVIEW,
  UPDATE_REVIEW,
  DELETE_REVIEW,
} from '../graphql/reviewsMutations';

export default function VehicleDataReviews(props) {
  const [showAddReviewBlock, setShowAddReviewBlock] = useState(false);
  const [reviews, setReviews] = useState([]);
  const modelId = props.modelId;

  const cssoverride = React.CSSProperties = {
    display: "block",
    margin: "0 auto",
  };

  const {
    loading: loadingReviews,
    error: loadingReviewsError,
    data,
    refetch: refetchReviews,
  } = useQuery(GET_REVIEWS_FOR_MODEL, {
    variables: { modelId: modelId },
  });

  const [addReview, { loading: mutationAddLoading, error: mutationAddError }] =
    useMutation(ADD_REVIEW);

  const [
    updateReview,
    { loading: mutationUpdateLoading, error: mutationUpdateError },
  ] = useMutation(UPDATE_REVIEW);

  const [
    deleteReview,
    { loading: mutationDeleteLoading, error: mutationDeleteError },
  ] = useMutation(DELETE_REVIEW);

  useEffect(() => {
    if (
      !loadingReviews &&
      !mutationAddLoading &&
      !mutationUpdateLoading &&
      !mutationDeleteLoading &&
      data
    ) {
      const reviewSourceList = props.reviewSources.map((item) => item._id);

      const newReviews = data.reviews.filter((item) =>
        reviewSourceList.includes(item.reviewSourceId)
      );

      // setReviews(data.reviews);
      setReviews(newReviews);
    }
  }, [
    loadingReviews,
    mutationAddLoading,
    mutationUpdateLoading,
    mutationDeleteLoading,
    data,
    // eslint-disable-next-line
    props.reviewSources,
  ]);

  if (loadingReviewsError) {
    return <p>Error: {loadingReviewsError}</p>;
  }

  if (mutationAddError) {
    return <p>Error: {mutationAddError}</p>;
  }
  if (mutationUpdateError) {
    return <p>Error: {mutationUpdateError}</p>;
  }
  if (mutationDeleteError) {
    return <p>Error: {mutationDeleteError}</p>;
  }

  function handleAddReviewSourceButtonClick() {
    setShowAddReviewBlock(true);
  }

  function handleCancelAddReview() {
    setShowAddReviewBlock(false);
  }

  async function handleAddReview(data) {
    console.log('adding: ', data);

    const result = await addReview({ variables: data });
    console.log('result: ', result);

    refetchReviews({ modelId: modelId });
    setShowAddReviewBlock(false);
    toast.success('Review successfully added');
  }

  async function handleUpdateReview(data) {
    console.log('to save: ', data);

    const result = await updateReview({
      variables: {
        reviewId: data._id,
        reviewSourceId: data.reviewSourceId,
        reviewSourceLogo: data.reviewSourceLogo,
        modelId: data.modelId,
        url: data.url,
        hideURL: data.hideURL,
        featured: data.featured,
        modelReviewed: data.modelReviewed,
        rating: data.rating,
        date: data.date,
        excerpt: data.excerpt,
        author: data.author,
        inUK: data.inUK,
      },
    });
    console.log('result: ', result);

    refetchReviews({ modelId: modelId });
    toast.success('Review successfully updated');
  }

  async function handleDeleteReview(reviewId) {
    const result = await deleteReview({
      variables: { reviewId: reviewId },
    });
    console.log('result: ', result);

    refetchReviews({ modelId: modelId });
    setShowAddReviewBlock(false);
    toast.success('Review successfully deleted');
  }

  return (
    <>
      {loadingReviews ? (
          <ClipLoader
          loading={true}
          cssOverride={cssoverride}
        />
      ) : (
        <div className='border px-4 pt-1 pb-4 mt-4 shadow'>
          <h4 className='mt-3 mb-4'>
            Media Reviews{' '}
            <span style={{ color: '#aaa', fontSize: '20px', marginLeft: 10 }}>
              {props.titleString}
            </span>
            <Button
              className='float-end'
              color='primary'
              size='sm'
              onClick={() => handleAddReviewSourceButtonClick(false)}
            >
              + Add a Review
            </Button>
          </h4>
          {showAddReviewBlock && (
            <AddReviewForm
              reviewSources={props.reviewSources.filter(
                (item) => item.isActive === true
              )}
              modelId={modelId}
              onCancel={handleCancelAddReview}
              onAdd={(data) => handleAddReview(data)}
            />
          )}
          <hr />
          {!_.isEmpty(reviews) &&
            reviews.map((review) => (
              <EditReviewForm
                key={review._id}
                review={review}
                reviewSourceName={
                  props.reviewSources.filter(
                    (item) => item._id === review.reviewSourceId
                  )[0].name
                }
                reviewSourceLogo={
                  props.reviewSources.filter(
                    (item) => item._id === review.reviewSourceId
                  )[0].logo
                }
                reviewSourceDisplayReview={
                  props.reviewSources.filter(
                    (item) => item._id === review.reviewSourceId
                  )[0].displayReview
                }
                reviewSourceDisplayScore={
                  props.reviewSources.filter(
                    (item) => item._id === review.reviewSourceId
                  )[0].displayReviewScores
                }
                onUpdate={(data) => handleUpdateReview(data)}
                onDelete={(reviewId) => handleDeleteReview(reviewId)}
              />
            ))}
        </div>
      )}
    </>
  );
}
