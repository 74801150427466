import React from 'react';
import Joi from 'joi-browser';
import Form from './common/form';
import { Button, Collapse, Card, CardBody, CardHeader } from 'reactstrap';
import DatePicker from 'react-datepicker';

class EditReviewForm extends Form {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      ...this.props,
      isOpen: false,
      showDeleteConfirm: false,
      data: {
        url: '',
        hideURL: false,
        featured: false,
        rating: '',
        modelReviewed: '',
        date: new Date(),
        excerpt: '',
        author: '',
        inUK: '',
      },
      inUKOptions: [
        { _id: 'edit-yes', name: 'Yes' },
        { _id: 'edit-no', name: 'No' },
      ],
      errors: {},
    };
  }

  schema = {
    _id: Joi.string(),
    modelId: Joi.string(),
    reviewSourceId: Joi.string(),
    reviewSource: Joi.string(),
    url: Joi.string().min(1).max(255).required().label('Review URL'),
    hideURL: Joi.boolean().allow(null).optional().label('Hide URL'),
    featured: Joi.boolean().allow(null).optional().label('Featured'),
    modelReviewed: Joi.string()
      .min(0)
      .max(255)
      .allow('')
      .allow(null)
      .optional()
      .label('Model reviewed'),
    rating: Joi.number().precision(1).required().min(0).max(10).label('Rating'),
    date: Joi.date().required().label('Rating date'),
    excerpt: Joi.string().min(1).max(10000).label('Excerpt'),
    author: Joi.string().min(0).max(255).allow('').allow(null).optional().label('Author'),
    inUK: Joi.string().required().label('UK review?'),
  };

  async componentDidMount() {
    this.setState({
      data: this.mapToViewModel(this.state.review),
      isOpen: false,
    });
  }

  mapToViewModel(review) {
    return {
      _id: review._id,
      modelId: review.modelId,
      reviewSourceId: review.reviewSourceId,
      reviewSource: review.reviewSource,
      modelReviewed: review.modelReviewed || '',
      url: review.url,
      hideURL: review.hideURL ? true : false,
      featured: review.featured ? true : false,
      date: new Date(review.date),
      rating: review.rating,
      excerpt: review.excerpt,
      author: review.author,
      inUK: review.inUK ? 'edit-yes' : 'edit-no',
    };
  }

  async toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  doSubmit = async () => {
    let { data } = this.state;

    data.inUK === 'edit-yes' ? (data.inUK = true) : (data.inUK = false);

    delete data.reviewSource;
    data.rating = parseFloat(data.rating).toFixed(1);

    this.props.onUpdate(data);
    this.setState({ data: this.mapToViewModel(data) });
    this.toggle();
  };

  handleDeleteBtnClick = () => {
    this.setState({ showDeleteConfirm: true });
  };

  handleDeleteConfirmBtnYesClick = async () => {
    this.props.onDelete(this.state.review._id);
  };

  handleDeleteConfirmBtnNoClick = () => {
    this.setState({ showDeleteConfirm: false });
  };

  handleDateChange = (date) => {
    let newData = { ...this.state.data };
    newData.date = date;
    this.setState({ data: newData });
  };

  handleHideURLChange = (hideURLSetting) => {
    let newData = { ...this.state.data };
    newData.hideURL = hideURLSetting.target.checked;
    this.setState({ data: newData });
  };

  handleFeaturedChange = (featuredSetting) => {
    let newData = { ...this.state.data };
    newData.featured = featuredSetting.target.checked;
    this.setState({ data: newData });
  };

  render() {
    const { data, showDeleteConfirm } = this.state;
    const { review, reviewSourceName, reviewSourceLogo, reviewSourceDisplayReview, reviewSourceDisplayScore } = this.props;

    return (
      <>
        <Card className='mt-3'>
          <CardHeader
            className='card-header-review'
            style={{
              backgroundColor: '#d4edda',
              borderColor: '#c3e6cb',
            }}
          >
            <Button
              color=''
              onClick={this.toggle}
              style={{ textAlign: 'left' }}
              block
            >
              <h5 style={{ color: '#01714b', marginTop: 0, marginBottom: 0 }}>
                <img
                  width='40'
                  height='40'
                  src= {`../../review-source-logos/` + reviewSourceLogo}
                  alt='Logo'
                  style={{ marginRight: '20px' }}
                />
                {/* <img
                  width='40'
                  height='40'
                  src={
                    reviewSourceLogo ||
                    'https://static.thecarexpert.co.uk/logos/default.png'
                  }
                  alt='Logo'
                  style={{ marginRight: '20px' }}
                /> */}
                {reviewSourceName}
                <span className='float-end'>
                <span style={{ color: '#001cee', paddingRight: '20px' }}>{data.featured ? '** Featured **' : ''}</span>
                {reviewSourceDisplayReview ? reviewSourceDisplayScore ? '' : <span style={{ fontSize: '15px', marginRight: '10px', color: '#008b8b'}}>Score will not be displayed</span> : <span style={{ fontSize: '15px', marginRight: '10px', color: '#008b8b'}}>Review will not be displayed</span>}
                  <span className='vehicle-type'>{data.rating} / 10</span>{' '}
                  {data.date ? data.date.toLocaleDateString() : ''}
                </span>
              </h5>
            </Button>
          </CardHeader>
          <Collapse isOpen={this.state.isOpen}>
            <CardBody>
              <form
                className='mb-4'
                onSubmit={this.handleSubmit}
                id={review._id}
              >
                <div className='col'>

                  {this.renderInput('url', 'Review url')}
                  <div className='form-group'>
                    <label>
                      Hide URL:
                      <span style={{ paddingLeft: '10px' }} />
                        <input
                          name="hideURLCheckbox"
                          type="checkbox"
                          checked={data.hideURL}
                          onChange={this.handleHideURLChange}
                        />
                    </label>
                  </div>
                  {this.renderInput('modelReviewed', 'Model reviewed')}
                  {this.renderInput('rating', 'Rating', '')}
                  <div className='form-group'>
                    <label>Rating date</label>
                    <DatePicker
                      selected={data.date}
                      dateFormat='dd/MM/yyyy'
                      className='form-control'
                      onChange={this.handleDateChange}
                    />
                  </div>
                  {this.renderTextarea('excerpt', 'Excerpt', '4')}
                  {this.renderInput('author', 'Author')}
                  {this.renderSelect(
                    'inUK',
                    'UK review?',
                    this.state.inUKOptions
                  )}
                  <div className='form-group'>
                    {!showDeleteConfirm && (
                      <>
                        {this.renderButton(
                          'Update',
                          'btn-success btn-sm float-end mb-3'
                        )}
                        <Button
                          className='me-2 mb-3 float-end'
                          size='sm'
                          color='danger'
                          onClick={() => this.handleDeleteBtnClick()}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                    {showDeleteConfirm && (
                      <>
                        <Button
                          className='mb-3 float-end'
                          size='sm'
                          outline
                          color='secondary'
                          onClick={() => this.handleDeleteConfirmBtnNoClick()}
                        >
                          No
                        </Button>
                        <Button
                          className='me-2 mb-3 float-end'
                          size='sm'
                          outline
                          color='danger'
                          onClick={() => this.handleDeleteConfirmBtnYesClick()}
                        >
                          Yes
                        </Button>
                        <span className='text-danger me-2 mb-3 float-end'>
                          Are you sure you want to delete this?
                        </span>
                      </>
                    )}
                  </div>
                  <div className='form-group' style={{ float: 'right', paddingRight: '40px', paddingTop: '0', marginTop: '-5px' }}>
                    <label>
                      Featured:
                      <span style={{ paddingLeft: '8px' }} />
                        <input
                          name="featuredCheckbox"
                          type="checkbox"
                          checked={data.featured}
                          onChange={this.handleFeaturedChange}
                        />
                    </label>
                  </div>
                </div>
              </form>
            </CardBody>
          </Collapse>
        </Card>
      </>
    );
  }
}

export default EditReviewForm;
