export function getEuroNCAPAdjustedRating(value, date) {

    if(value === '') return '';


    const dt1 = new Date(date);
    const dt2 = new Date()

    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60 * 24);
    let depModifier = Math.abs(Math.round(diff/365.25));

    if(depModifier > 0) {
      depModifier = depModifier - 1
    }

    return Math.round(value * (1 - (depModifier * 0.03)))
}

export function getEuroNCAPAdultOccupantScore(value) {

// Get values from this table: EuroNCAP adult occupant rating adjusted

    // const euroNCAPAdultOccupantRanking_A = 95;
    const euroNCAPAdultOccupantRanking_B = 83;
    const euroNCAPAdultOccupantRanking_C = 78;
    const euroNCAPAdultOccupantRanking_D = 71;
    const euroNCAPAdultOccupantRanking_E = 62;

    if(value === '') return '';

    if(value === 0) {
      return 'F'
    }

    if(value > euroNCAPAdultOccupantRanking_B) {
      return 'A';
    }
    if(value > euroNCAPAdultOccupantRanking_C) {
      return 'B';
    }
    if(value > euroNCAPAdultOccupantRanking_D) {
      return 'C';
    }
    if(value > euroNCAPAdultOccupantRanking_E) {
      return 'D';
    }
    if(value <= euroNCAPAdultOccupantRanking_E) {
      return 'E';
    }

    return '-';
}

export function getEuroNCAPChildOccupantScore(value) {

// Get values from this table: EuroNCAP child occupant rating adjusted

    // const euroNCAPChildOccupantRanking_A = 92;
    const euroNCAPChildOccupantRanking_B = 81;
    const euroNCAPChildOccupantRanking_C = 74;
    const euroNCAPChildOccupantRanking_D = 68;
    const euroNCAPChildOccupantRanking_E = 59;

    if(value === '') return '';

    if(value === 0) {
      return 'F'
    }

    if(value > euroNCAPChildOccupantRanking_B) {
      return 'A';
    }
    if(value > euroNCAPChildOccupantRanking_C) {
      return 'B';
    }
    if(value > euroNCAPChildOccupantRanking_D) {
      return 'C';
    }
    if(value > euroNCAPChildOccupantRanking_E) {
      return 'D';
    }
    if(value <= euroNCAPChildOccupantRanking_E) {
      return 'E';
    }

    return '-';
}

export function getEuroNCAPSafetyAssistScore(value) {

// Get values from this table: EuroNCAP safety assist rating adjusted

    // const euroNCAPSafetyAssistRanking_A = 95;
    const euroNCAPSafetyAssistRanking_B = 75;
    const euroNCAPSafetyAssistRanking_C = 64;
    const euroNCAPSafetyAssistRanking_D = 57;
    const euroNCAPSafetyAssistRanking_E = 48;

    if(value === '') return '';

    if(value === 0) {
      return 'F'
    }

    if(value > euroNCAPSafetyAssistRanking_B) {
      return 'A';
    }
    if(value > euroNCAPSafetyAssistRanking_C) {
      return 'B';
    }
    if(value > euroNCAPSafetyAssistRanking_D) {
      return 'C';
    }
    if(value > euroNCAPSafetyAssistRanking_E) {
      return 'D';
    }
    if(value <= euroNCAPSafetyAssistRanking_E) {
      return 'E';
    }

    return '-';
}

export function getEuroNCAPVulnerableRoadUsersScore(value) {

// Get values from this table: EuroNCAP vulnerabale road users rating adjusted

    // const euroNCAPVulnerableRoadUsersRanking_A = 87;
    const euroNCAPVulnerableRoadUsersRanking_B = 71;
    const euroNCAPVulnerableRoadUsersRanking_C = 63;
    const euroNCAPVulnerableRoadUsersRanking_D = 56;
    const euroNCAPVulnerableRoadUsersRanking_E = 48;

    if(value === '') return '';

    if(value === 0) {
      return 'F'
    }

    if(value > euroNCAPVulnerableRoadUsersRanking_B) {
      return 'A';
    }
    if(value > euroNCAPVulnerableRoadUsersRanking_C) {
      return 'B';
    }
    if(value > euroNCAPVulnerableRoadUsersRanking_D) {
      return 'C';
    }
    if(value > euroNCAPVulnerableRoadUsersRanking_E) {
      return 'D';
    }
    if(value <= euroNCAPVulnerableRoadUsersRanking_E) {
      return 'E';
    }

    return '-';
}

export function getEuroNCAPCombinedScore(value) {

// Get values from this table: EuroNCAP combined rating adjusted

    // const euroNCAPCombinedRanking_A = 357;
    const euroNCAPCombinedRanking_B = 307;
    const euroNCAPCombinedRanking_C = 281;
    const euroNCAPCombinedRanking_D = 249;
    const euroNCAPCombinedRanking_E = 219;

    if(value === '') return '';

    if(value === 0) {
      return 'F'
    }

    if(value > euroNCAPCombinedRanking_B) {
      return 'A';
    }
    if(value > euroNCAPCombinedRanking_C) {
      return 'B';
    }
    if(value > euroNCAPCombinedRanking_D) {
      return 'C';
    }
    if(value > euroNCAPCombinedRanking_E) {
      return 'D';
    }
    if(value <= euroNCAPCombinedRanking_E) {
      return 'E';
    }

    return '-';
}

export function getCO2CombinedAverage(allCO2AvgArray) {
    
    var total = 0;
    var count = 0;

    allCO2AvgArray.forEach(function(item, index) {
      if(item > 0) {
        total += item;
        count++;
      }
    });

    return (count > 0 ? Math.round(total / count) : '');
}

export function getCO2CombinedScore(value) {

// Get values from this table: CO2 Combined average

    // const co2CombinedRanking_A = 112;
    const co2CombinedRanking_B = 136;
    const co2CombinedRanking_C = 165;
    const co2CombinedRanking_D = 224;
    const co2CombinedRanking_E = 394;

    if(value === '') return '';

    if(value < co2CombinedRanking_B) {
      return 'A';
    }
    if(value < co2CombinedRanking_C) {
      return 'B';
    }
    if(value < co2CombinedRanking_D) {
      return 'C';
    }
    if(value < co2CombinedRanking_E) {
      return 'D';
    }
    if(value > co2CombinedRanking_D) {
      return 'E';
    }

    return '-';
}

export function getCO2Score(value) {

// Get values from this table: CO2 average

    // const co2Ranking_A = 112;
    const co2Ranking_B = 137;
    const co2Ranking_C = 160;
    const co2Ranking_D = 199;
    const co2Ranking_E = 394;

    if(value === null) return '';

    if(value < co2Ranking_B) {
      return 'A';
    }
    if(value < co2Ranking_C) {
      return 'B';
    }
    if(value < co2Ranking_D) {
      return 'C';
    }
    if(value < co2Ranking_E) {
      return 'D';
    }
    if(value > co2Ranking_D) {
      return 'E';
    }

    return '-';
}

export function getMPGScore(value) {
  
// Get values from this table: MPG average

    // const mpgRanking_A = 100;
    const mpgRanking_B = 58;
    const mpgRanking_C = 49;
    const mpgRanking_D = 41;
    const mpgRanking_E = 32;

    if(value === null) return '';

    if(value > mpgRanking_B) {
      return 'A';
    }
    if(value > mpgRanking_C) {
      return 'B';
    }
    if(value > mpgRanking_D) {
      return 'C';
    }
    if(value > mpgRanking_E) {
      return 'D';
    }
    if(value <= mpgRanking_E) {
      return 'E';
    }

    return '-';
}

export function getCombinedFuelEconomy(petrolMPG, dieselMPG, hybridMPG, plugInHybridMPG) {

  let getCombinedFuelEconomy = 0;
  let divisor = 0;

  if(petrolMPG !== null) {
    getCombinedFuelEconomy += petrolMPG;
    divisor += 1;
  }
  if(dieselMPG !== null) {
    getCombinedFuelEconomy += dieselMPG;
    divisor += 1;
  }
  if(hybridMPG !== null) {
    getCombinedFuelEconomy += hybridMPG;
    divisor += 1;
  }
  if(plugInHybridMPG !== null) {
    getCombinedFuelEconomy += plugInHybridMPG;
    divisor += 1;
  }

  if(divisor === 0) {
    getCombinedFuelEconomy = '';
  } else {
    getCombinedFuelEconomy = (getCombinedFuelEconomy / divisor).toFixed(1);
  }

  return getCombinedFuelEconomy;

}
// export function getCombinedFuelEconomy(petrolMPG, dieselMPG, hybridMPG, plugInHybridMPG) {

//   let getCombinedFuelEconomy = 0;
//   let divisor = 0;

//   if(petrolMPG !== null) {
//     getCombinedFuelEconomy += 0.6*petrolMPG;
//     divisor += 1;
//   }
//   if(dieselMPG !== null) {
//     getCombinedFuelEconomy += 0.7*dieselMPG;
//     divisor += 1;
//   }
//   if(hybridMPG !== null) {
//     getCombinedFuelEconomy += 0.7*hybridMPG;
//     divisor += 1;
//   }
//   if(plugInHybridMPG !== null) {
//     getCombinedFuelEconomy += 0.3*plugInHybridMPG;
//     divisor += 1;
//   }

//   if(divisor === 0) {
//     getCombinedFuelEconomy = '';
//   } else {
//     getCombinedFuelEconomy = (getCombinedFuelEconomy / divisor).toFixed(1);
//   }

//   return getCombinedFuelEconomy;

// }

export function getBatteryRangeScore(value) {

// Get values from this table: Battery range

    // const batteryRangeRanking_A = 433;
    const batteryRangeRanking_B = 298;
    const batteryRangeRanking_C = 227;
    const batteryRangeRanking_D = 59;
    const batteryRangeRanking_E = 37;

    if(value === null) return '';

    if(value > batteryRangeRanking_B) {
      return 'A';
    }
    if(value > batteryRangeRanking_C) {
      return 'B';
    }
    if(value > batteryRangeRanking_D) {
      return 'C';
    }
    if(value > batteryRangeRanking_E) {
      return 'D';
    }
    if(value <= batteryRangeRanking_E) {
      return 'E';
    }

    return '-';
}

export function getElectricalEfficiencyScore(value) {

  // Get values from this table: Electrical efficiency
  
    // const electricalEfficiencyRanking_B = 7.4;
    const electricalEfficiencyRanking_B = 4.5;
    const electricalEfficiencyRanking_C = 4.2;
    const electricalEfficiencyRanking_D = 3.9;
    const electricalEfficiencyRanking_E = 3.4;

    if(value === null) return '';

    if(value > electricalEfficiencyRanking_B) {
      return 'A';
    }
    if(value > electricalEfficiencyRanking_C) {
      return 'B';
    }
    if(value > electricalEfficiencyRanking_D) {
      return 'C';
    }
    if(value > electricalEfficiencyRanking_E) {
      return 'D';
    }
    if(value <= electricalEfficiencyRanking_E) {
      return 'E';
    }

    return '-';
}

export function getInsuranceGroupScore(value) {

// Get values from this table: Insurance group  

    // const insuranceGroupRanking_A = 18;
    const insuranceGroupRanking_B = 25;
    const insuranceGroupRanking_C = 32;
    const insuranceGroupRanking_D = 41;
    const insuranceGroupRanking_E = 49;
    const insuranceGroupRanking_F = 50;

    if(value === null) return '';

    if(value < insuranceGroupRanking_B) {
      return 'A';
    }
    if(value < insuranceGroupRanking_C) {
      return 'B';
    }
    if(value < insuranceGroupRanking_D) {
      return 'C';
    }
    if(value < insuranceGroupRanking_E) {
      return 'D';
    }
    if(value < insuranceGroupRanking_F) {
      return 'E';
    }
    if(value === insuranceGroupRanking_F) {
      return 'F';
    }

    return '-';
}

export function getYear1SMRScore(value) {
    
// Get values from this table: SMR Year 1

    // const year1SMRScore_A = 164;
    const year1SMRScore_B = 211;
    const year1SMRScore_C = 286;
    const year1SMRScore_D = 422;
    const year1SMRScore_E = 1197;

    if(value === null) return '';

    if(value < year1SMRScore_B) {
      return 'A';
    }
    if(value < year1SMRScore_C) {
      return 'B';
    }
    if(value < year1SMRScore_D) {
      return 'C';
    }
    if(value < year1SMRScore_E) {
      return 'D';
    }
    if(value >= year1SMRScore_E) {
      return 'E';
    }

    return '-';
}

export function getYear2SMRScore(value) {
    
// Get values from this table: SMR Year 2

    // const year2SMRScore_A = 449;
    const year2SMRScore_B = 532;
    const year2SMRScore_C = 651;
    const year2SMRScore_D = 918;
    const year2SMRScore_E = 1842;

    if(value === null) return '';

    if(value < year2SMRScore_B) {
      return 'A';
    }
    if(value < year2SMRScore_C) {
      return 'B';
    }
    if(value < year2SMRScore_D) {
      return 'C';
    }
    if(value < year2SMRScore_E) {
      return 'D';
    }
    if(value >= year2SMRScore_E) {
      return 'E';
    }

    return '-';
}

export function getYear3SMRScore(value) {
 
// Get values from this table: SMR Year 3  

    // const year3SMRScore_A = 717;
    const year3SMRScore_B = 861;
    const year3SMRScore_C = 1070;
    const year3SMRScore_D = 1486;
    const year3SMRScore_E = 3244;

    if(value === null) return '';

    if(value < year3SMRScore_B) {
      return 'A';
    }
    if(value < year3SMRScore_C) {
      return 'B';
    }
    if(value < year3SMRScore_D) {
      return 'C';
    }
    if(value < year3SMRScore_E) {
      return 'D';
    }
    if(value >= year3SMRScore_E) {
      return 'E';
    }

    return '-';
}

export function getYear4SMRScore(value) {

// Get values from this table: SMR Year 4  

    // const year4SMRScore_A = 937;
    const year4SMRScore_B = 1082;
    const year4SMRScore_C = 1320;
    const year4SMRScore_D = 1719;
    const year4SMRScore_E = 4090;

    if(value === null) return '';

    if(value < year4SMRScore_B) {
      return 'A';
    }
    if(value < year4SMRScore_C) {
      return 'B';
    }
    if(value < year4SMRScore_D) {
      return 'C';
    }
    if(value < year4SMRScore_E) {
      return 'D';
    }
    if(value >= year4SMRScore_E) {
      return 'E';
    }

    return '-';
}

export function getYear5SMRScore(value) {

// Get values from this table: SMR Year 5  

    // const year5SMRScore_A = 1256;
    const year5SMRScore_B = 1436;
    const year5SMRScore_C = 1742;
    const year5SMRScore_D = 2292;
    const year5SMRScore_E = 5435;
    
    if(value === null) return '';

    if(value < year5SMRScore_B) {
      return 'A';
    }
    if(value < year5SMRScore_C) {
      return 'B';
    }
    if(value < year5SMRScore_D) {
      return 'C';
    }
    if(value < year5SMRScore_E) {
      return 'D';
    }
    if(value >= year5SMRScore_E) {
      return 'E';
    }

    return '-';
}

export function getOverallSMRscore(value) {
    
// Get values from this table: SMR overall

    // const overallSMRScore_A = 3530;
    const overallSMRScore_B = 4123;
    const overallSMRScore_C = 5062;
    const overallSMRScore_D = 6798;
    const overallSMRScore_E = 15025;

    if(value === null) return '';

    if(value < overallSMRScore_B) {
      return 'A';
    }
    if(value < overallSMRScore_C) {
      return 'B';
    }
    if(value < overallSMRScore_D) {
      return 'C';
    }
    if(value < overallSMRScore_E) {
      return 'D';
    }
    if(value >= overallSMRScore_E) {
      return 'E';
    }

    return '-';
}

export function getReliabilityRating(modelReliability) {

    const thisModelReliability = (modelReliability === null || modelReliability === undefined || modelReliability === "" ? {} : JSON.parse(modelReliability));

    if(Object.keys(thisModelReliability).length !== 0) {
      return Math.round(thisModelReliability.WarrantyReliabilityScore*10);
    }

    return '';
}

export function getReliabilityRatingScore(value) {

// Get values from this table: Reliability rating

    // const reliabilityScore_A = 87;
    const reliabilityScore_B = 79;
    const reliabilityScore_C = 69;
    const reliabilityScore_D = 62;
    const reliabilityScore_E = 41;

    if(value === '') return '';

    if(value > reliabilityScore_B) {
      return 'A';
    }
    if(value > reliabilityScore_C) {
      return 'B';
    }
    if(value > reliabilityScore_D) {
      return 'C';
    }
    if(value > reliabilityScore_E) {
      return 'D';
    }
    if(value <= reliabilityScore_E) {
      return 'E';
    }

    return '-';
}

export function getNewCarMediaScore(value) {

// Get values from this table: New car media rating

    // const newCarMediaScore_A = 100;
    const newCarMediaScore_B = 75;
    const newCarMediaScore_C = 69;
    const newCarMediaScore_D = 63;
    const newCarMediaScore_E = 55;

    if(value === null) return '';

    if(value > newCarMediaScore_B) {
      return 'A';
    }
    if(value > newCarMediaScore_C) {
      return 'B';
    }
    if(value > newCarMediaScore_D) {
      return 'C';
    }
    if(value > newCarMediaScore_E) {
      return 'D';
    }
    if(value <= newCarMediaScore_E) {
      return 'E';
    }

    return '-';
}

export function getUsedCarMediaScore(value) {

  // Get values from this table: Used car media rating
  
      // const usedCarMediaScore_A = 100;
      const usedCarMediaScore_B = 78;
      const usedCarMediaScore_C = 73;
      const usedCarMediaScore_D = 67;
      const usedCarMediaScore_E = 60;
  
      if(value === null) return '';
  
      if(value > usedCarMediaScore_B) {
        return 'A';
      }
      if(value > usedCarMediaScore_C) {
        return 'B';
      }
      if(value > usedCarMediaScore_D) {
        return 'C';
      }
      if(value > usedCarMediaScore_E) {
        return 'D';
      }
      if(value <= usedCarMediaScore_E) {
        return 'E';
      }
  
      return '-';
}

export function getNewCarRunningCostsScore(value) {

  // Get values from this table: New car running costs rating
  
      // const newCarRunningCostsScore_A = 66.9;
      const newCarRunningCostsScore_B = 50.2;
      const newCarRunningCostsScore_C = 45.3;
      const newCarRunningCostsScore_D = 41.4;
      const newCarRunningCostsScore_E = 28.1;
  
      if(value === null) return '';
  
      if(value > newCarRunningCostsScore_B) {
        return 'A';
      }
      if(value > newCarRunningCostsScore_C) {
        return 'B';
      }
      if(value > newCarRunningCostsScore_D) {
        return 'C';
      }
      if(value > newCarRunningCostsScore_E) {
        return 'D';
      }
      if(value <= newCarRunningCostsScore_E) {
        return 'E';
      }
  
      return '-';
}

export function getUsedCarRunningCostsScore(value) {

  // Get values from this table: Used car running costs rating
  
      // const usedCarRunningCostsScore_A = 68.5;
      const usedCarRunningCostsScore_B = 51.6;
      const usedCarRunningCostsScore_C = 44.7;
      const usedCarRunningCostsScore_D = 41.1;
      const usedCarRunningCostsScore_E = 28;
  
      if(value === null) return '';
  
      if(value > usedCarRunningCostsScore_B) {
        return 'A';
      }
      if(value > usedCarRunningCostsScore_C) {
        return 'B';
      }
      if(value > usedCarRunningCostsScore_D) {
        return 'C';
      }
      if(value > usedCarRunningCostsScore_E) {
        return 'D';
      }
      if(value <= usedCarRunningCostsScore_E) {
        return 'E';
      }
  
      return '-';
}

export function getNewCarExpertRatingScore(value) {

  // Get values from this table: New car Expert rating
  
      // const newCarExpertRatingScore_A = 86.3;
      const newCarExpertRatingScore_B = 70.5;
      const newCarExpertRatingScore_C = 65.3;
      const newCarExpertRatingScore_D = 61.1;
      const newCarExpertRatingScore_E = 55.6;
  
      if(value === null) return '';
  
      if(value > newCarExpertRatingScore_B) {
        return 'A';
      }
      if(value > newCarExpertRatingScore_C) {
        return 'B';
      }
      if(value > newCarExpertRatingScore_D) {
        return 'C';
      }
      if(value > newCarExpertRatingScore_E) {
        return 'D';
      }
      if(value <= newCarExpertRatingScore_E) {
        return 'E';
      }
  
      return '-';
}

export function getUsedCarExpertRatingScore(value) {

  // Get values from this table: Used car Expert rating
  
      // const usedCarExpertRatingScore_A = 84;
      const usedCarExpertRatingScore_B = 70.8;
      const usedCarExpertRatingScore_C = 66;
      const usedCarExpertRatingScore_D = 61.6;
      const usedCarExpertRatingScore_E = 55.3;
  
      if(value === null) return '';
  
      if(value > usedCarExpertRatingScore_B) {
        return 'A';
      }
      if(value > usedCarExpertRatingScore_C) {
        return 'B';
      }
      if(value > usedCarExpertRatingScore_D) {
        return 'C';
      }
      if(value > usedCarExpertRatingScore_E) {
        return 'D';
      }
      if(value <= usedCarExpertRatingScore_E) {
        return 'E';
      }
  
      return '-';
}